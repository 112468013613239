import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

import CatHeader from "../components/Category/CatHeader"
import CatProducts from "../components/Category/CatProducts"
import Suggestions from "../components/Product/Suggestions"

const category = (props) => {
  const { name, description, products } = props.data.cat
  const productsSuggest = props.data.products.edges

  let suggestions = []

  const productsRemovedAdventures = productsSuggest.filter(
    (pro) => pro.node.categories[0].slug !== "adventures"
  )

  if (productsRemovedAdventures.length > 4) {
    const shuffled = productsRemovedAdventures.sort(() => 0.5 - Math.random())
    suggestions = shuffled.slice(0, 4)
  } else {
    suggestions = productsRemovedAdventures
  }

  return (
    <Layout location={props.location.pathname}>
      <SEO
        title={``}
        description={``}
        metaImg={``}
        location={props.location.pathname}
      />
      <CatHeader name={name} description={description} />
      <CatProducts name={name} products={products} />
      <Suggestions suggestions={suggestions} />
    </Layout>
  )
}

export const query = graphql`
  query catPage($id: String!) {
    cat: strapiCategory(id: { eq: $id }) {
      name
      description
      products {
        name
        id
        slug
        price
        featured_image {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }

    products: allStrapiProduct {
      edges {
        node {
          name
          id
          strapiId
          slug
          price
          featured_image {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          categories {
            name
            slug
          }
        }
      }
    }
  }
`

export default category
