import React from "react"
import styled from "styled-components"
import {
  bodyCopy,
  colors,
  headlineThree,
  standardWrapper,
} from "../../Utilities"

const CatHeader = ({ name, description }) => {
  return (
    <CatHeaderStyled>
      <div className="wrapper">
        <h2>Shop {name}</h2>
        <p>{description}</p>
      </div>
    </CatHeaderStyled>
  )
}

const CatHeaderStyled = styled.div`
  background-color: ${colors.colorTertiary};
  padding: 2rem 0;

  .wrapper {
    ${standardWrapper};
  }

  h2 {
    ${headlineThree};
  }

  p {
    ${bodyCopy};
  }

  h2,
  p {
    width: 100%;
    margin: 0;
    color: ${colors.white};
    text-align: center;
  }
`

export default CatHeader
