import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import { bodyCopy, standardWrapper, colors } from "../../Utilities"

import CatProduct from "./CatProduct"

const CatProducts = ({ name, products }) => {
  return (
    <CatProductsStyled>
      <div className="wrapper">
        <div className="catNav">
          <Link to="/shop">Shop</Link>
          <span> &#62; </span>
          <span>{name}</span>
        </div>
        <div className="products">
          {products.map(product => (
            <CatProduct product={product} name={name} key={product.id} />
          ))}
        </div>
      </div>
    </CatProductsStyled>
  )
}

const CatProductsStyled = styled.div`
  .wrapper {
    ${standardWrapper};
  }

  .catNav {
    width: 100%;
    margin-left: 2rem;

    a,
    span {
      ${bodyCopy};
    }

    a:hover {
      color: ${colors.colorTertiary};
    }
  }

  .products {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
  }
`

export default CatProducts
